<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Repository</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/articles/repositories/list"
              type="Add"
              button-text="Repository"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                class="form"
                id="product_form"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <div class="row">
                  <div class="col-xl-2"></div>
                  <div class="col-xl-8">
                    <div class="form-group">
                      <ValidationProvider
                        name="Name"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label class="font-weight-bolder" for="repository_name"
                          >Name*</label
                        >
                        <input
                          v-model="repository.name"
                          id="repository_name"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          :class="{ 'is-invalid': errors[0] }"
                          placeholder="Enter repository name"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <div class="form-group mb-8">
                        <label class="font-weight-bolder">Articles</label>

                        <div
                          v-for="(article, index) in repository.articles"
                          :key="article.index"
                          class="form-group row"
                        >
                          <div class="col-md-7 mb-3">
                            <div class="input-group">
                              <input
                                type="text"
                                v-model="repository.articles[index].name"
                                class="form-control form-control-solid"
                                style="font-weight: bold"
                                disabled
                                placeholder="Article name"
                              />
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="input-group">
                              <input
                                type="text"
                                v-model="repository.articles[index].quantity"
                                class="form-control form-control-solid"
                                style="font-weight: bold"
                                placeholder="Quantity"
                              />
                            </div>
                          </div>
                          <div class="col-lg-1" style="text-align: right">
                            <a
                              @click="removeArticle(index)"
                              href="javascript:"
                              class="btn font-weight-bold btn-danger btn-icon"
                            >
                              <i class="la la-remove"></i>
                            </a>
                          </div>
                        </div>

                        <button
                          @click="toggleArticlesModal"
                          type="button"
                          class="btn btn-light-primary font-weight-bold btn-block"
                        >
                          Add Article
                        </button>
                      </div>
                    </div>
                    <div class="separator separator-dashed my-10"></div>
                  </div>
                  <div class="col-xl-2"></div>
                </div>
              </form>
            </ValidationObserver>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="articles_modal" centered hide-header hide-footer>
          <div class="row">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('articles_modal')"
              >Close</b-button
            >
            <div class="col-12">
              <div class="form-group">
                <label class="font-weight-bolder">Articles*</label>
                <vSelect
                  v-model="tempArticle.id"
                  class="form-control form-control--vt-select"
                  placeholder="Select article"
                  :options="articles"
                  :reduce="(article) => article['@id']"
                  label="name"
                >
                  <template #option="{ name, quantity, unit }">
                    <li>
                      {{ name }}
                      <span
                        class="label label-primary label-inline font-weight-lighter ml-2"
                        >{{ quantity }} {{ unit }}</span
                      >
                    </li>
                  </template>
                </vSelect>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="font-weight-bolder">Quantity*</label>
                <input
                  v-model="tempArticle.quantity"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  @click="addArticle"
                  class="btn btn-light-primary btn-block"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import vSelect from 'vue-select'

export default {
  name: 'RepositoriesNew',
  components: {
    CardToolbar,
    vSelect,
  },
  data() {
    return {
      repository: {
        name: null,
        articles: [],
      },
      articles: [],
      tempArticle: {
        id: null,
        name: null,
        quantity: null,
      },
      isArticlesLoaded: false,
    }
  },
  mounted() {
    this.getArticles('articles').then(({ data }) => {
      this.articles = [...data['hydra:member']]
      this.isArticlesLoaded = true
    })
  },
  methods: {
    async getArticles(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async onSubmit() {
      this.repository.articles.forEach((article) => {
        const articleId = article.id
        ApiService.get('articles/' + articleId).then(({ data }) => {
          ApiService.update('articles', articleId, {
            quantity: data.quantity - article.quantity + '',
          })
        })
      })

      this.postRepository(this.repository)
        .then(({ status }) => {
          if (status === 201) {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Repository successful add in database',
            })
            setTimeout(() => {
              this.$router.push({ name: 'articles-repositories-list' })
            }, 500)
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Repository submit error',
          })
        })
    },
    async postRepository(entity) {
      try {
        return await ApiService.post('article_stores', entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    addArticle() {
      let selectedArticle = this.articles.find(
        (article) => article['@id'] === this.tempArticle.id
      )

      this.repository.articles.push({
        id: selectedArticle.id,
        name: selectedArticle.name,
        quantity: this.tempArticle.quantity,
      })

      this.tempArticle.id = null
      this.tempArticle.name = null
      this.tempArticle.quantity = null

      this.$root.$emit('bv::hide::modal', 'articles_modal')
    },
    removeArticle(index) {
      const confirmation = confirm('Do you want to delete this Article?')
      if (confirmation === false) return

      this.repository.articles.splice(index, 1)
    },
    toggleArticlesModal() {
      this.$root.$emit('bv::show::modal', 'articles_modal')
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--vt-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
